<template>
  <DashboardTemplate>
    <template v-slot:content>
        <div class="pl-4 my-4">
            <div class="text-center">
              <i class="text-center fas fa-exclamation-triangle fa-3x text-orange-400"></i>
              <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 md:leading-10 truncate">You don't have access to this feature</h2>
              <div class="font-medium tracking-tight text-secondary">Upgrade to VIP to gain access</div>
            </div>
        </div>     
    </template>
  </DashboardTemplate>
</template>

<script>
export default {
  name: 'Home'
}
</script>
